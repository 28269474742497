
import Card from "../../components/Card";
import SerMQ from "../../image/products/SerMQ.png";
import P6025 from "../../image/products/6025.png";
import p642 from "../../image/products/642.png";
import Helmet from 'react-helmet';


export default function CuttingTools() {
    return (
        <div className="container">
            <Helmet>
                <title>Станки для режущего инструмента</title>
                <meta name="description"
                      content="Список станков подходящие для обработки режущего инструмента"/>
                <meta name="keywords" content="Режущий, инструмент, Станки, механической, обработки"/>
                <meta name="robots" content="index, follow"/>
                <link rel="canonical" href="https://stm-market.ru/CuttingTools"/>
            </Helmet>
            <h1>Станки для режущего инструмента</h1>
            <div className="goods">
                <Card image={SerMQ} name='Станок универсально-заточной для инструментов серии MQ' link = '/SerMq'/>
                <Card image={P6025} name='Станок универсально-заточный для инструментов GD-6025Q' link = '/P6025'/>
                <Card image={p642} name='Станок заточной универсальный 642-й серии' link = '/p642'/>
            </div>

        </div>
    );
}

