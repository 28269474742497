import {Container, Table} from "react-bootstrap";
import productImg from "../../image/products/SMG150.png"
import Helmet from 'react-helmet';
import './Product.scss';
import {useState} from "react";

export default function SMG150() {
    const [fullscreen, setFullscreen] = useState(false)

    const openClose = () => {
        setFullscreen(!fullscreen)
    }
    return (
        <Container id="product-container">
            <Helmet>
                <title>Станок профилешлифовальный с ЧПУ SMG 150-й серии</title>
                <meta name="description"
                      content="Профилешлифовальные станки с ЧПУ SMG 150-й серии предназначены для обработки профильных пазов на таких деталях как «протяжка круглая шлицевая», «протяжка плоская профильная», «долбяк». На станке возможно выполнение работ по шлифовке плоских и фасонных поверхностей, шлицев с эвольвентным и трапецеидальным профилем, обработке наружных фасонных поверхностей крошеров (накатников), круглых и плоских протяжек по передней поверхности."/>
                <meta name="keywords" content="профилешлифовальный,150-й серии, ЧПУ"/>
                <meta name="robots" content="index, follow"/>
                <link rel="canonical" href="https://stm-market.ru/SMG150"/>
            </Helmet>
            <h1>Станок профилешлифовальный с ЧПУ SMG 150-й серии</h1>
            <div className={`full-image ${fullscreen ? 'active-screen' : null}`}>
                <div className="cross" onClick={openClose}>
                    <span className='cross-right'></span>
                    <span className='cross-left'></span>
                </div>
                <img className='fullscreen-img' src={productImg} alt={productImg}/>
                <div className="background"></div>

            </div>
            <div className='info'>
                <div style={{
                    backgroundImage: 'url(' + productImg + ')',
                    backgroundSize: '100%',
                    backgroundRepeat: 'no-repeat',
                    borderRadius: '10px',
                }} className="product-image" onClick={openClose}>
                </div>
                <h3>Описание</h3>
                <p>Профилешлифовальные станки с ЧПУ SMG 150-й серии предназначены для обработки профильных пазов на
                    таких деталях как «протяжка круглая шлицевая», «протяжка плоская профильная», «долбяк». На станке
                    возможно выполнение работ по шлифовке плоских и фасонных поверхностей, шлицев с эвольвентным и
                    трапецеидальным профилем, обработке наружных фасонных поверхностей крошеров (накатников), круглых и
                    плоских протяжек по передней поверхности. <br/>
                    В качестве шлифовальных кругов, в основном, должны использоваться абразивные электрокорундовые
                    круги, профилируемые непосредственно на станке. При необходимости могут применяться как
                    высокостойкие эльборовые (СВN) специальные профильные шлифовальные круги на керамической связке с
                    возможностью их периодической правки на станке, так и эльборовые круги на металлическом основании,
                    не подлежащие правке на станке. <br/>
                    Конструктивные особенности станка в сочетании с программным обеспечением позволяют выполнять
                    разнообразные технологические переходы. <br/>
                    Специальное программное обеспечение «Stanexim» служит для подготовки управляющих программ шлифования
                    заготовки, правки шлифовального круга, измерения и обработки результатов, наладки и выставки
                    констант станка и состоит из следующих основных модулей: правка, шлифование, наладка станка,
                    измерение. <br/>
                    Внешняя оболочка программы написана с помощью специальных библиотек компании Stanexim, которые
                    позволяют быстро создавать удобные и масштабируемые интерфейсы программ с динамической справочной
                    информацией.
                </p>


            </div>


            <h3>Технические характеристики</h3>

            <Table striped bordless="true" hover>
                <thead>
                <tr className='table-title'>
                    <th>Характеристика</th>
                    <th>Значение</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>Размеры поверхности стола (Длина х Ширина), мм</td>
                    <td>1600 х 500</td>
                </tr>
                <tr>
                    <td>Наибольшая длина детали, устанавливаемая в центрах, мм</td>
                    <td>1200</td>
                </tr>
                <tr>
                    <td>Высота расположения осей передней и задней бабок над зеркалом стола, не менее, мм</td>
                    <td>160</td>
                </tr>
                </tbody>
            </Table>

        </Container>
    )
}
