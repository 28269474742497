import {Container, Table} from "react-bootstrap";
import productImg from "../../image/products/SMG630.png"
import Helmet from 'react-helmet';
import './Product.scss';
import {useState} from "react";


export default function SMG630() {
    const [fullscreen, setFullscreen] = useState(false)

    const openClose = () => {
        setFullscreen(!fullscreen)
    }

    return (
        <Container id="product-container">
            <Helmet>
                <title>Станок заточной с ЧПУ для торцовых фрез SMG 630-й серии</title>
                <meta name="description"
                      content="Станок заточной для торцовых фрез SMG 630-й серии предназначен для заточки торцовых фрез с режущей частью из твердого сплава и быстрорежущей стали.
                    Заточка фрезы производится торцом чашечного абразивного круга по задним поверхностям зубьев с применением смазочно-охлаждающей жидкости."/>
                <meta name="keywords" content="заточной, торцовых, 630-й серии, ЧПУ"/>
                <meta name="robots" content="index, follow"/>
                <link rel="canonical" href="https://stm-market.ru/SMG630"/>
            </Helmet>
            <h1>Станок заточной с ЧПУ для торцовых фрез SMG 630-й серии</h1>
            <div className={`full-image ${fullscreen ? 'active-screen' : null}`}>
                <div className="cross" onClick={openClose}>
                    <span className='cross-right'></span>
                    <span className='cross-left'></span>
                </div>
                <img className='fullscreen-img' src={productImg} alt={productImg}/>
                <div className="background"></div>

            </div>
            <div className='info'>
                <div style={{
                    backgroundImage: 'url(' + productImg + ')',
                    backgroundSize: '100%',
                    backgroundRepeat: 'no-repeat',
                    borderRadius: '10px',
                    cursor: 'pointer',
                }} className="product-image" onClick={openClose}>
                </div>
                <h3>Описание</h3>
                <p>Станок заточной для торцовых фрез SMG 630-й серии предназначен для заточки торцовых фрез с режущей
                    частью из твердого сплава и быстрорежущей стали.
                    Заточка фрезы производится торцом чашечного абразивного круга по задним поверхностям зубьев с
                    применением смазочно-охлаждающей жидкости.
                    Управление станком осуществляется с помощью контроллера. Для выполнения цикла оператором с
                    многофункциональной панели управления вводятся данные:
                    <br/>- число зубьев;
                    <br/> - подача и число рабочих ходов предварительной и окончательной обработки;
                    <br/>- число ходов выхаживания. <br/>
                    Использование контроллера позволяет значительно повысить надежность станка, а также расширить его
                    функциональные возможности. Применение разнообразных функциональных модулей обеспечивает эффективное
                    управление рабочим циклом станка. Визуализация всего процесса управления станком осуществляется с
                    помощью дисплея. На экран выводятся данные о величине снимаемого припуска, сообщения
                    диагностического характера и прочая информация.
                    Наличие контактного измерительного щупа обеспечивает полный цикл измерений за один оборот фрезы.
                    Станок выполнен как модульная конструкция из унифицированных (базовых) и оригинальных узлов, имеет
                    ограждение кабинетного типа. Доступ в рабочую зону обеспечивается раздвижной дверью, которая
                    снабжена электрическим выключателем, блокирующим рабочий цикл при открытии.
                </p>
            </div>


            <h3>Технические характеристики</h3>

            <Table striped bordless="true" hover>
                <thead>
                <tr className='table-title'>
                    <th>Характеристика</th>
                    <th>Значение</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>Пределы диаметров затачиваемых фрез, мм</td>
                    <td>80…630</td>
                </tr>
                <tr>
                    <td>Пределы чисел затачиваемых резцов, шт.</td>
                    <td>1 … 120</td>
                </tr>
                <tr>
                    <td>Максимальная масса устанавливаемых фрез (с учётом массы оправки), кг</td>
                    <td>125</td>
                </tr>
                </tbody>
            </Table>
        </Container>
    )
}
