import React from 'react';
import "./cardBlock.scss"
import Card from "./Card";
import SMG630 from "../image/products/SMG630.png";
import p400 from "../image/products/sr-400.png";
import p642 from "../image/products/642.png";
import P6025 from "../image/products/6025.png";
import SerMQ from "../image/products/SerMQ.png";
import SMG405 from "../image/products/SMG405.png";
import M3080 from "../image/products/M3080.png";
import SMG150 from "../image/products/SMG150.png";
import SMG360 from "../image/products/SMG360.png";
import TTK4020 from "../image/products/TTK4020.png";
import SMG050 from "../image/products/SMG050.png";
import SMG250 from "../image/products/SMG250.png";

const ProductBlock = (props) => (
    <div className="main-cards-block">
        <h1>Станки</h1>
        <div className="main-cards">
            <Card image={SMG630} name='Станок заточной с ЧПУ для торцовых фрез SMG 630-й серии' link='/products/SMG630'/>
            <Card image={p400} name='Станок заточной с ЧПУ для червячных фрез SMG 400-й серии' link='/products/p400'/>
            <Card image={p642} className="card-last" name='Станок заточной универсальный 642-й серии' link='/products/p642'/>
            <Card image={P6025} name='Станок универсально-заточный для инструментов GD-6025Q' link='/products/P6025'/>
            <Card image={SerMQ} name='Станок универсально-заточной для инструментов серии MQ' link='/products/SerMq'/>
            <Card image={SMG405} name='Станок зубошлифовальный с ЧПУ SMG 405-й серии' link='/products/SMG405'/>
            <Card image={M3080} name='Станок круглошлифовальный для внутренней и наружной шлифовки GD-M3080A/B'
                  link='/products/M3080'/>
            <Card image={SMG150} name='Станок профилешлифовальный с ЧПУ SMG 150-й серии' link='/products/SMG150'/>
            <Card image={SMG360} name='Станок резьбошлифовальный с ЧПУ SMG 360-й серии' link='/products/SMG360'/>
            <Card image={TTK4020} name='Стенд испытательный механической прочности шлифовальных кругов ТТК4020'
                  link='/products/TTK4020'/>
            <Card image={SMG050} name='Станок шлифовально-заточной с ЧПУ SMG 050-й серии' link='/products/SMG050'/>
            <Card image={SMG250} name='Станок шлифовально-заточной с ЧПУ SMG 250-й серии' link='/products/SMG250'/>
        </div>
    </div>
);

export default ProductBlock;
