
import Card from "../../components/Card";

import SMG150 from "../../image/products/SMG150.png";
import Helmet from 'react-helmet';


export default function Broaches() {
    return (
        <div className="container">
            <Helmet>
                <title>Станки для обработки протяжек</title>
                <meta name="description"
                      content="Список станков подходящие для обработки протяжек"/>
                <meta name="keywords" content="протяжек, Станки, механической, обработки"/>
                <meta name="robots" content="index, follow"/>
                <link rel="canonical" href="https://stm-market.ru/Broaches"/>
            </Helmet>
            <h1>Станки для протяжек</h1>

            <div className="goods">
                <Card image={SMG150} name='Станок профилешлифовальный с ЧПУ SMG 150-й серии' link = '/SMG150'/>
            </div>

        </div>
    );
}

