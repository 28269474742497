import React from 'react';
import './contactBlock.scss'

function ContactBlock() {
    return (
        <div className="contact-block">
            <h2>Контактная информация</h2>
            <div className="contacts">
                <div className="contact-card">
                    <h3>Великие Луки</h3>
                    <p>
                        Адрес: РФ, Великие Луки,<br/>пр-т Октябрьский, 125 <br/>
                        Тел.: <a href="tel:+78115365206">+7 (8115) 36-52-06</a> <br/>
                        Тел.: <a href="tel:+78115365375">+7 (8115) 36-53-75</a> <br/>
                        E-mail: <a href="mailto:stm-market@mail.ru">stm-market@mail.ru</a> <br/>
                        Время работы: 9.00 - 18.00
                    </p>
                </div>
                <div className="contact-card">
                    <h3>Москва</h3>
                    <p>
                        Адрес: РФ, Москва,<br/>1-й Институтский пр-д, 5с 1 <br/>
                        Тел.: <a href="tel:+74993808405">+7 (499) 348-83-84</a> <br/>
                        Тел.: <a href="tel:+74993808405">+7 (499) 380-84-05</a> <br/>
                        E-mail: <a href="mailto:stm-market@mail.ru">stm-market@mail.ru</a> <br/>
                        Время работы: 9.00 - 18.00
                    </p>
                </div>
                <div className="contact-card">
                    <h3>Витебск</h3>
                    <p>
                        Адрес: РБ, Витебск, <br/>пр-т Черняховского, 33, пом. 6 <br/>
                        Тел.: <a href="tel:+375212679645"> +375 (212) 67-96-56</a> <br/>
                        Тел.: <a href="tel:+375212679645"> +375 (212) 67-96-45</a> <br/>
                        E-mail: <a href="mailto:stm-market@mail.ru">stm-market@mail.ru</a> <br/>
                        Время работы: 9.00 - 18.00
                    </p>
                </div>
            </div>
        </div>
    );
}

export default ContactBlock;
