import Slider from "../components/Slider";
import {Helmet} from 'react-helmet';
import ProductBlock from "../components/ProductBlock";
import ContactBlock from "../components/ContactBlock";
import ScrollArrow from "../components/ScrollArrow";


function Home() {
    return (
        <>
            <Helmet>
                <title>Главная страница</title>
                <meta name="description" content="Главная страница, новости, акции, скидки, станки и контактная информация"/>
                <meta name="robots" content="index, follow"/>
                <link rel="canonical" href="https://stm-market.ru/"/>
            </Helmet>
            <Slider/>
            <ProductBlock/>
            <ContactBlock/>
            <ScrollArrow/>
        </>
    );
}

export default Home;
