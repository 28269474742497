import {Container, Table} from "react-bootstrap";
import productImg from "../../image/products/SMG050.png"
import Helmet from 'react-helmet';
import {useState} from "react";
import './Product.scss';

export default function SMG050() {
    const [fullscreen, setFullscreen] = useState(false)

    const openClose = () => {
        setFullscreen(!fullscreen)
    }
    return (
        <Container id="product-container">
            <Helmet>
                <title>Станок шлифовально-заточной с ЧПУ SMG 050-й серии</title>
                <meta name="description"
                      content="Шлифовально-заточные станки с ЧПУ SMG 050-й серии предназначены для шлифования сложных винтовых профилей и формообразования режущей части инструментов (концевые цилиндрические фрезы с прямым и сферическим торцом, концевые конические фрезы с прямым и сферическим торцом, свёрла спиральные ступенчатые, развёртки цилиндрические, развёртки конические, зенкеры цилиндрические, зенкеры конические, метчики, борфрезы и т.п.)."/>
                <meta name="keywords" content="зшлифовально-заточнойй, SMG 050-й серии, ЧПУ"/>
                <meta name="robots" content="index, follow"/>
                <link rel="canonical" href="https://stm-market.ru/SMG050"/>
            </Helmet>
            <h1>Станок шлифовально-заточной с ЧПУ SMG 050-й серии</h1>
            <div className={`full-image ${fullscreen ? 'active-screen' : null}`}>
                <div className="cross" onClick={openClose}>
                    <span className='cross-right'></span>
                    <span className='cross-left'></span>
                </div>
                <img className='fullscreen-img' src={productImg} alt={productImg}/>
                <div className="background"></div>

            </div>

            <div className='info'>
                <div style={{
                    backgroundImage: 'url(' + productImg + ')',
                    backgroundSize: '100%',
                    backgroundRepeat: 'no-repeat',
                    borderRadius: '10px',
                }} className="product-image" onClick={openClose}>
                </div>
                <h3>Описание</h3>
                <p>Шлифовально-заточные станки с ЧПУ SMG 050-й серии предназначены для шлифования сложных винтовых
                    профилей и формообразования режущей части инструментов (концевые цилиндрические фрезы с прямым и
                    сферическим торцом, концевые конические фрезы с прямым и сферическим торцом, свёрла спиральные
                    ступенчатые, развёртки цилиндрические, развёртки конические, зенкеры цилиндрические, зенкеры
                    конические, метчики, борфрезы и т.п.). <br/>
                    В качестве шлифовальных кругов применяются высокостойкие эльборовые (СВN) специальные профильные
                    шлифовальные круги как на керамической связке, так и эльборовые круги на металлическом основании, не
                    подлежащие правке на станке. Для обеспечения высоких качественных показателей инструмента обработка
                    осуществляется с применением смазочно-охлаждающей жидкости. <br/>
                    Станок оснащен шестью управляемыми осями, мотор-шпинделем с системой жидкостного охлаждения, полной
                    защитой рабочей зоны с раздвижной дверью и боковыми дверями для обслуживания, освещением рабочей
                    зоны, системой отсоса и фильтрации аэрозолей. <br/>
                    Система ЧПУ дает возможность перехода на ручное управление.
                </p>


            </div>


            <h3>Технические характеристики</h3>

            <Table striped bordless="true" hover>
                <thead>
                <tr className='table-title'>
                    <th>Характеристика</th>
                    <th>Значение</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>Длина рабочей части обрабатываемого изделия, мм</td>
                    <td>200</td>
                </tr>
                <tr>
                    <td>Максимальный диаметр обрабатываемого изделия, мм</td>
                    <td>150</td>
                </tr>
                <tr>
                    <td>Максимальная длина обрабатываемого изделия для периферийного шлифования, мм</td>
                    <td>250</td>
                </tr>
                <tr>
                    <td>Максимальная длина обрабатываемого изделия для торцевого шлифования, мм</td>
                    <td>150</td>
                </tr>
                </tbody>
            </Table>

        </Container>
    )
}
