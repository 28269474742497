import {Col, Container, Row} from "react-bootstrap";
import aboutLogo from "../image/Rectangle 13.png"
import Card from "../components/Card";
import SMG630 from "../image/products/SMG630.png";
import p400 from "../image/products/sr-400.png";
import p642 from "../image/products/642.png";
import P6025 from "../image/products/6025.png";
import {Helmet} from 'react-helmet';

function About() {
    return (
        <Container>
            <Helmet>
                <title>О компании</title>
                <meta name="description" content="Информация о компании СТМ-маркет" />
                <meta name="keywords" content="STM-MARKET, stm-market, станки, СТМ-Маркет" />
                <meta name="robots" content="index, follow" />
                <link rel="canonical" href="https://stm-market.ru/about" />
            </Helmet>
            <h1>STM-MARKET</h1>
            <Row>
                <Col lg={{span: 8, offset: 1}} md={{span: 10, offset: 1}} sm={{span: 12, offset: 0}}
                     xs={{span: 12, offset: 0}} className="justify-center">
                    <p className="about-text">Компания СТМ-Маркет представляет модельный ряд шлифовально-заточных
                        станков с ЧПУ, предназначенных для изготовления и переточки концевого инструмента, червячных
                        фрез и протяжек. Ассортимент продукции дополняют профилешлифовальные станки с ЧПУ:
                        зубошлифовальные, шлицешлифовальные, резьбошлифовальные. Комплексные пакеты и отдельные модули
                        ПО обеспечивают оперативное решение производственных задач.
                        Для получения более подробной информации обратитесь в компанию СТМ-Маркет!</p>
                </Col>
                <Col lg={{span: 3, offset: 0}} md={{span: 10, offset: 1}} sm={{span: 12, offset: 0}}
                     className="justify-center">
                    <img src={aboutLogo} alt="" className="about-img"/>
                </Col>
            </Row>
            <Row>
                <h2 className='about-products'>Станки</h2>
            </Row>
            <div className="goods">
                <Card image={SMG630} name='Станок заточной с ЧПУ для торцовых фрез SMG 630-й серии' link = '/products/SMG630'/>
                <Card image={p400} name='Станок заточной с ЧПУ для червячных фрез SMG 400-й серии' link = '/products/p400'/>
                <Card image={p642} name='Станок заточной универсальный 642-й серии' link = '/products/p642'/>
                <Card image={P6025} name='Станок универсально-заточный для инструментов GD-6025Q' link = '/products/P6025'/>
            </div>


        </Container>
    )
}

export default About
