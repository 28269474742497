
import Card from "../../components/Card";

import TTK4020 from "../../image/products/TTK4020.png";
import Helmet from 'react-helmet';


export default function GrindingWheel() {
    return (
        <div className="container">
            <Helmet>
                <title>Станки для шлифовальных кругов</title>
                <meta name="description"
                      content="Список станков подходящие для обработки шлифовальных кругов"/>
                <meta name="keywords" content="шлифовальных, кругов, Станки"/>
                <meta name="robots" content="index, follow"/>
                <link rel="canonical" href="https://stm-market.ru/GrindingWheel"/>
            </Helmet>
            <h1>Станки для шлифовальных кругов</h1>

            <div className="goods">
                <Card image={TTK4020} name='Стенд испытательный механической прочности шлифовальных кругов ТТК4020' link = '/TTK4020'/>

            </div>

        </div>
    );
}

