import Card from "../../components/Card";

import M3080 from "../../image/products/M3080.png";
import Helmet from 'react-helmet';


export default function Axis() {
    return (
        <div className="container">
            <Helmet>
                <title>Станки для обработки осей</title>
                <meta name="description"
                      content="Список станков подходящие для обработки осей"/>
                <meta name="keywords" content="осей, Станки, механической, обработки"/>
                <meta name="robots" content="index, follow"/>
                <link rel="canonical" href="https://stm-market.ru/Axis"/>
            </Helmet>
            <h1>Станки для осей</h1>

            <div className="goods">
                <Card image={M3080} name='Станок круглошлифовальный для внутренней и наружной шлифовки GD-M3080A/B'
                      link='/M3080'/>
            </div>

        </div>
    );
}

