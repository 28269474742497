
import Card from "../../components/Card";

import SMG630 from "../../image/products/SMG630.png";
import Helmet from 'react-helmet';


export default function EndMillingCutter() {
    return (
        <div className="container">
            <Helmet>
                <title>Станки для торцевых фрез</title>
                <meta name="description"
                      content="Список станков подходящие для обработки торцевых фрез"/>
                <meta name="keywords" content="торцевых, фрез, Станки"/>
                <meta name="robots" content="index, follow"/>
                <link rel="canonical" href="https://stm-market.ru/EndMillingCutter"/>
            </Helmet>
            <h1>Станки для торцевых фрез</h1>

            <div className="goods">
                <Card image={SMG630} name='Станок заточной с ЧПУ для торцовых фрез SMG 630-й серии' link = '/SMG630'/>
            </div>

        </div>
    );
}

