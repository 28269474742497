import {Container, Table} from "react-bootstrap";
import productImg from "../../image/products/M3080.png"
import Helmet from 'react-helmet';
import {useState} from "react";
import './Product.scss';

export default function M3080() {
    const [fullscreen, setFullscreen] = useState(false)

    const openClose = () => {
        setFullscreen(!fullscreen)
    }
    return (
        <Container id="product-container">
            <Helmet>
                <title>Станок круглошлифовальный для внутренней и наружной шлифовки GD-M3080A/B</title>
                <meta name="description" content="Круглошлифовальные станки GD-M3080A/B применяются для шлифовки малых валов, цилиндрических гильз,
                    игольчатых вентилей, поршней и других конусных и сферических поверхностей."/>
                <meta name="keywords" content="Круглошлифовальные, GD-M3080A/B, Продольное перемещение рабочего стола" />
                <meta name="robots" content="index, follow"/>
                <link rel="canonical" href="https://stm-market.ru/M3080"/>
            </Helmet>
            <h1>Станок круглошлифовальный для внутренней и наружной шлифовки GD-M3080A/B</h1>
            <div className={`full-image ${fullscreen ? 'active-screen' : null}`}>
                <div className="cross" onClick={openClose}>
                    <span className='cross-right'></span>
                    <span className='cross-left'></span>
                </div>
                <img className='fullscreen-img' src={productImg} alt={productImg}/>
                <div className="background"></div>

            </div>
            <div className='info'>
                <div style={{
                    backgroundImage: 'url(' + productImg + ')',
                    backgroundSize: '100%',
                    backgroundRepeat: 'no-repeat',
                    borderRadius: '10px',
                    cursor: 'pointer',
                }} className="product-image" onClick={openClose}>
                </div>
                <h3>Описание</h3>
                <p>Круглошлифовальные станки GD-M3080A/B применяются для шлифовки малых валов, цилиндрических гильз,
                    игольчатых вентилей, поршней и других конусных и сферических поверхностей. Изделие закрепляется
                    трехцанговым зажимным патроном, цанговым патроном, а также специальными захватами. <br/>
                    Станок круглошлифовальный для внутренней и наружной шлифовки широко применяется в производстве
                    деталей для приборостроения, автомобильной индустрии, электромеханики, подшипникостроения,
                    текстильной промышленности, судостроения, а также производства швейных машин и прочего
                    оборудования. <br/>
                    Продольное перемещение рабочего стола осуществляется как гидравлически, так и вручную. Шлифовальная
                    бабка и передняя бабка вращаются равномерно. В гидравлической системе используются шестерни с
                    отличными характеристиками <br/>
                    Станок применяется в цехах по ремонту инструментов и оборудования, а также в цехах по мелкосерийному
                    и среднесерийному производству.
                </p>


            </div>


            <h3>Технические характеристики</h3>

            <Table striped bordless="true" hover>
                <thead>
                <tr className='table-title'>
                    <th>Характеристика</th>
                    <th>Значение GD-M3080A/B</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>Диаметр шлифовки, мм</td>
                    <td>Φ2-Φ80</td>
                </tr>
                <tr>
                    <td>Длина шлифовки, мм</td>
                    <td>300</td>
                </tr>
                <tr>
                    <td>Высота неподвижного центра, мм</td>
                    <td>115</td>
                </tr>
                <tr>
                    <td>Шероховатость поверхности (наруж./внутр.), µm</td>
                    <td>Ra0,25/0,63</td>
                </tr>
                </tbody>
            </Table>

        </Container>
    )
}
