import Card from "../components/Card";
import p400 from "../image/products/sr-400.png";
import TTK4020 from "../image/products/TTK4020.png";
import SerMQ from "../image/products/SerMQ.png";
import Helmet from "react-helmet";

function Event() {
    return (
        <div className="container">
            <Helmet>
                <title>Металлообработка 2023</title>
                <meta name="description" content='Металлообработка 2023, крупное мероприятие посвященное новым технологиям в металлообработке'/>
                <meta name="robots" content="index, follow"/>
                <meta name="keywords" content="STM-MARKET, stm-market, станки, СТМ-Маркет" />
                <link rel="canonical" href="https://stm-market.ru/Event"/>
            </Helmet>
            <div className="carte">
                <iframe title='event-carte'
                        src="https://www.google.ru/maps/d/embed?mid=1ONwr0ef4mEB1LjQChsWsu9NHgRI&ehbc=2E312F"></iframe>
            </div>
            <div className="event-context">
                <h1>Металлообработка 2023</h1>
                <h2>Москва, ЦВК "Экспоцентр", 22-26 мая</h2>
                <h3>Приглашаем Вас и специалистов предприятия посетить наш стенд <span>FF025</span> (павильон "FORUM")
                </h3>
                <p className='event-description'>Изготовление и поставка оборудования предприятия Республики Беларусь,
                    КНР</p>
                <div className="event-text">
                    <ul className='event-ul'>
                        <li className='event-list-item'>Станки с ЧПУ: <ul>
                            <li className="inner-event-list">Профилешлифовальные (зубо-, шлице-, резьбошлифовальные,
                                червячно-шлифовальные);
                            </li>
                            <li className="inner-event-list">Шлифовально-заточные (изготовление и заточка концевого
                                инструмента, заточка червячных, торцовых фрез, специального режущего инструмента по
                                техническому заданию).
                            </li>
                        </ul></li>
                        <li className='event-list-item'>Станки универсально-заточные:<ul>
                            <li className="inner-event-list">Приспособления к универсально заточным станкам для заточки
                                режущего инструмента (сверл, фрез, в т.ч. червячных, резцов, метчиков, долбяков и т.д.),
                                выполнение операций круглого и плоского шлифования с использованием дополнительных
                                приспособлений и электромагнитных плит.
                            </li>
                        </ul></li>
                        <li className='event-list-item'>Натурные образцы:<ul>
                            <li className='inner-event-list'>Станок с ЧПУ для заточки червячных фрез SMG400HF3;
                            </li>
                            <li className='inner-event-list'>Стенд испытательный механической прочности шлифовальных кругов  ТТК4020;
                            </li>
                            <li className='inner-event-list'>Станок универсально-заточной с двухсторонним шпинделем MQ-6025A.
                            </li>
                        </ul></li>
                    </ul>
                    <p className='addict'>ЕЖЕДНЕВНО В 15-30: КРУГЛЫЙ СТОЛ И ПОДВЕДЕНИЕ ИТОГОВ ПЕРЕГОВОРОВ.</p>
                </div>
            </div>
            <div className="goods">
                <Card image={p400} name='Станок заточной с ЧПУ SMG для червячных фрез 400-й серии' link='/p400'/>
                <Card image={TTK4020} name='Стенд испытательный механической прочности шлифовальных кругов ТТК4020'
                      link='/TTK4020'/>
                <Card image={SerMQ} name='Станок универсально-заточной для инструментов серии MQ' link='/SerMq'/>
            </div>
        </div>

    );
}

export default Event
