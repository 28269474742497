import {Container, Table} from "react-bootstrap";
import productImg from "../../image/products/SMG360.png"
import Helmet from 'react-helmet';
import {useState} from "react";
import './Product.scss';


export default function SMG360() {
    const [fullscreen, setFullscreen] = useState(false)

    const openClose = () => {
        setFullscreen(!fullscreen)
    }
    return (
        <Container id="product-container">
            <Helmet>
                <title>Станок резьбошлифовальный с ЧПУ SMG 360-й серии</title>
                <meta name="description"
                      content="Резьбошлифовальные станки с ЧПУ SMG 360-й серии предназначены для серийного производства крепежной
                    резьбы, червяков, ходовых винтов, шариковых винтовых пар, накатных плашек, резьбовых калибров,
                    прямых и спиральных метчиков, резьбовых фрез, сложных винтовых профилей и выполнения прочих
                    специальных операций."/>
                <meta name="keywords" content="резьбошлифовальный, 360-й серии, ЧПУ"/>
                <meta name="robots" content="index, follow"/>
                <link rel="canonical" href="https://stm-market.ru/SMG360"/>
            </Helmet>
            <h1>Станок резьбошлифовальный с ЧПУ SMG 360-й серии</h1>
            <div className={`full-image ${fullscreen ? 'active-screen' : null}`}>
                <div className="cross" onClick={openClose}>
                    <span className='cross-right'></span>
                    <span className='cross-left'></span>
                </div>
                <img className='fullscreen-img' src={productImg} alt={productImg}/>
                <div className="background"></div>

            </div>

            <div className='info'>
                <div style={{
                    backgroundImage: 'url(' + productImg + ')',
                    backgroundSize: '100%',
                    backgroundRepeat: 'no-repeat',
                    borderRadius: '10px',
                }} className="product-image" onClick={openClose}>
                </div>
                <h3>Описание</h3>
                <p>Резьбошлифовальные станки с ЧПУ SMG 360-й серии предназначены для серийного производства крепежной
                    резьбы, червяков, ходовых винтов, шариковых винтовых пар, накатных плашек, резьбовых калибров,
                    прямых и спиральных метчиков, резьбовых фрез, сложных винтовых профилей и выполнения прочих
                    специальных операций. <br/>
                    Шлифование винтовых профилей производится за счет интерполированного движения профильного
                    шлифовального круга и изделия. <br/>
                    В качестве шлифовальных кругов применяются абразивные электрокорундовые круги, профилируемые
                    непосредственно на станке, а при необходимости – высокостойкие эльборовые специальные профильные
                    шлифовальные круги на керамической связке, которые подлежат периодической правке на станке. <br/>
                    Станок имеет ограждение кабинетного типа, предназначенное для предотвращения разбрызгивания
                    смазочно-охлаждающей жидкости (СОЖ). Доступ в рабочую зону обеспечивают передние раздвижные двери,
                    которые снабжены электрическими выключателями, блокирующими запуск рабочего цикла станка, если
                    открыта хотя бы одна из дверей. Станок оснащен централизованной автоматической системой смазки
                    направляющих качения. <br/>
                    На станке используются комплектующие хорошо зарекомендовавших себя мировых производителей. <br/>
                </p>


            </div>


            <h3>Технические характеристики</h3>

            <Table striped bordless="true" hover>
                <thead>
                <tr className='table-title'>
                    <th>Характеристика</th>
                    <th>Значение</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>Максимальный диаметр обрабатываемой заготовки, мм</td>
                    <td>360</td>
                </tr>
                <tr>
                    <td>Максимальная длина шлифования, мм</td>
                    <td>400</td>
                </tr>
                </tbody>
            </Table>

        </Container>
    )
}
