import {Container, Row, Table} from "react-bootstrap";
import productImg from "../../image/products/642.png"
import Helmet from 'react-helmet';
import {useState} from "react";
import './Product.scss';

export default function P642() {
    const [fullscreen, setFullscreen] = useState(false)

    const openClose = () => {
        setFullscreen(!fullscreen)
    }
    return (
        <Container id="product-container">
            <Helmet>
                <title>Станок заточной универсальный 642-й серии</title>
                <meta name="description"
                      content="Универсальные заточные станки 642-й серии предназначены для заточки и доводки абразивными, алмазными и эльборовыми кругами различных видов режущих инструментов из инструментальной стали, твёрдых сплавов и минералокерамики: сверла, зенкера, развертки, метчики, фрезы концевые и насадные, в т.ч. червячные, резцы, долбяки…"/>
                <meta name="keywords" content="заточной универсальный, 642-й серии, ЧПУ"/>
                <meta name="robots" content="index, follow"/>
                <link rel="canonical" href="https://stm-market.ru/p642"/>
            </Helmet>
            <h1>Станок заточной универсальный 642-й серии</h1>
            <div className={`full-image ${fullscreen ? 'active-screen' : null}`}>
                <div className="cross" onClick={openClose}>
                    <span className='cross-right'></span>
                    <span className='cross-left'></span>
                </div>
                <img className='fullscreen-img' src={productImg} alt={productImg}/>
                <div className="background"></div>

            </div>
            <div className='info'>
                <div style={{
                    backgroundImage: 'url(' + productImg + ')',
                    backgroundSize: '100%',
                    backgroundRepeat: 'no-repeat',
                    borderRadius: '10px',
                }} className="product-image" onClick={openClose}>
                    <a href="https://disk.yandex.ru/i/euE0Av6eW2Vgig" target='_blank'  rel="noreferrer">
                        <button>Приспособления</button>
                    </a>
                </div>
                <h3>Описание</h3>
                <p>
                    Универсальные заточные станки 642-й серии предназначены для заточки и доводки абразивными, алмазными
                    и эльборовыми кругами различных видов режущих инструментов из инструментальной стали, твёрдых
                    сплавов и минералокерамики: сверла, зенкера, развертки, метчики, фрезы концевые и насадные, в т.ч.
                    червячные, резцы, долбяки…<br/>
                    Конструкция станка представляет собой цельную литую станину из чугуна, на которой установлены
                    основание продольного перемещения стола и каретка поперечного перемещения шлифовальной бабки. Привод
                    продольного стола - комбинированный (ручной и гидрофицированный). На рабочем столе установлен
                    поворотный стол, обеспечивающий необходимый разворот в горизонтальной плоскости. Привод поперечного
                    перемещения шлифовальной бабки - ручной, привод вертикального перемещения шлифовальной бабки -
                    комбинированный (ручной и электромеханический). Шпиндельная бабка имеет возможность поворота на 360
                    градусов в горизонтальной плоскости и наклона в вертикальной плоскости, как по часовой стрелки, так
                    и против часовой стрелки. Изменение частоты вращения шлифовального шпинделя и шпинделя вращения
                    заготовки может осуществляется бесступенчато (опция). <br/>
                </p>
            </div>


            <h3>Технические характеристики</h3>

            <Table striped bordless="true" hover>
                <thead>
                <tr>
                    <th>Характеристика</th>
                    <th>Значение</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>Наибольший диаметр инструмента, обрабатываемого в центровых бабках, мм</td>
                    <td>250</td>
                </tr>
                <tr>
                    <td>Наибольший диаметр инструмента, обрабатываемого в универсальной бабке, мм</td>
                    <td>450</td>
                </tr>
                <tr>
                    <td>Наибольшая длина обрабатываемой поверхности, мм</td>
                    <td>400</td>
                </tr>
                </tbody>
            </Table>


            <Row>
                <h3>Дополнительная информация</h3>
                <p>По заявке Покупателя станки универсальные заточные могут оснащаться дополнительными приспособлениями
                    и оснасткой для заточки разнообразного инструмента, а также системой отсоса пыли.</p>
            </Row>
        </Container>
    )
}
