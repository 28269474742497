import React, { useState, useEffect } from 'react';
import './ScrollArrow.scss'
const ScrollAwareArrow = () => {
    const [isVisible, setIsVisible] = useState(true);

    const handleScroll = () => {
        const isAtBottom = window.innerHeight + window.scrollY >= document.body.offsetHeight;
        setIsVisible(!isAtBottom);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return isVisible ? (
        <div
            style={{
                position: 'fixed',
                bottom: '20px',
                left: '49.4%',
                transform: 'translateY(50%)',
                zIndex: '9999',
                cursor: 'pointer',
                animation: 'bounceAndFade 2s infinite',
            }}
            onClick={() => window.scrollTo({ top: document.body.offsetHeight, behavior: 'smooth' })}
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="42"
                height="42"
                viewBox="0 0 24 24"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="none"
                stroke="currentColor"
            >
                <line x1="12" y1="19" x2="5" y2="12"></line>
                <line x1="12" y1="19" x2="19" y2="12"></line>
            </svg>
        </div>
    ) : null;
};

export default ScrollAwareArrow;
