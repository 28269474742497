import first from '../image/sliderImg/banner1.jpg';
import second from '../image/sliderImg/banner2.jpg';
import third from '../image/sliderImg/banner3.jpg';
import fourth from '../image/sliderImg/banner4.jpg';


// import { useState, useEffect } from 'react';
// import second from '../image/sliderImg/second.jpg'
// import third from '../image/sliderImg/third.jpg'


import {Button, Carousel} from "react-bootstrap";
import {Link} from "react-router-dom";


export default function Slider() {
    // const [windowSize, setWindowSize] = useState(window.innerWidth);
    // const [firstImage, setFirstImage] = useState(windowSize < 768 ? mobileFirst : first);

/*    useEffect(() => {
        function handleResize() {
            const newWindowSize = window.innerWidth;
            setWindowSize(newWindowSize);
            const newImage = newWindowSize < 500 ? mobileFirst : first;
            setFirstImage(newImage);
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);*/

    return (
        <div className="slider">
            <Carousel fade interval={2000}>
                <Carousel.Item>
                    <img src={fourth} alt="/products/SMG630" style = {{ display: 'block',
                        height: '100%',
                        width: '100%'}}/>
                    <Carousel.Caption>
                        <Link to="/products/TTK4020"> <Button className="slider-btn">Подробнее</Button></Link>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={third} alt="first" style = {{ display: 'block',
                        height: '100%',
                        width: '100%'}}/>
                    <Carousel.Caption>
                        <Link to="/products/p642"> <Button className="slider-btn">Подробнее</Button></Link>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={second} alt="first" style = {{ display: 'block',
                        height: '100%',
                        width: '100%'}}/>
                    <Carousel.Caption>
                        <Link to="/products/p400"> <Button className="slider-btn">Подробнее</Button></Link>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={first} alt="first" style = {{ display: 'block',
                    height: '100%',
                    width: '100%'}}/>
                    <Carousel.Caption>
                        <Link to="/products/p6025"> <Button className="slider-btn">Подробнее</Button></Link>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
        </div>
    )
}
