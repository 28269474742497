import Card from "../../components/Card";

import p400 from "../../image/products/sr-400.png";
import Helmet from 'react-helmet';


export default function WormMillingCutter() {
    return (
        <div className="container">
            <Helmet>
                <title>Станки для червячных фрез</title>
                <meta name="description"
                      content="Список станков подходящие для обработки обработки метчиков"/>
                <meta name="keywords" content="червячных, обработки, фрез, Станки"/>
                <meta name="robots" content="index, follow"/>
                <link rel="canonical" href="https://stm-market.ru/WormMillingCutter"/>
            </Helmet>
            <h1>Станки для червячных фрез</h1>
            <div className="goods">
                <Card image={p400} name='Станок заточной с ЧПУ для червячных фрез SMG 400-й серии' link='/p400'/>
            </div>
        </div>
    );
}

