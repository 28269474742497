import {Container, Table} from "react-bootstrap";
import productImg from "../../image/products/SerMQ.png"
import Helmet from 'react-helmet';
import {useState} from "react";
import './Product.scss';

export default function SerMq() {
    const [fullscreen, setFullscreen] = useState(false)

    const openClose = () => {
        setFullscreen(!fullscreen)
    }
    return (
        <Container id="product-container">
            <Helmet>
                <title>Станок универсально-заточный для инструментов MQ-6025A/YA</title>
                <meta name="description"
                      content="Универсально-заточные станки для инструментов серии MQ предназначены для заточки инструментов из
                    быстрорежущей стали, твердых сплавов и других материалов. С применением приспособлений, поставляемых
                    за отдельную плату, станки можно использовать для заточки цилиндрических, наружных и плоских канавок
                    и формовочных поверхностей, а также шлифовать разнообразные зубчатые колеса, червячные фрезы,
                    фасонные фрезы, концевые сферические фрезы, спиральные сверла, конические развертки и проч."/>
                <meta name="keywords" content="заточной универсальный, MQ-6025A/YA"/>
                <meta name="robots" content="index, follow"/>
                <link rel="canonical" href="https://stm-market.ru/SerMq"/>
            </Helmet>
            <h1>Станок универсально-заточный для инструментов MQ-6025A/YA</h1>
            <div className={`full-image ${fullscreen ? 'active-screen' : null}`}>
                <div className="cross" onClick={openClose}>
                    <span className='cross-right'></span>
                    <span className='cross-left'></span>
                </div>
                <img className='fullscreen-img' src={productImg} alt={productImg}/>
                <div className="background"></div>

            </div>
            <div className='info'>
                <div style={{
                    backgroundImage: 'url(' + productImg + ')',
                    backgroundSize: '100%',
                    backgroundRepeat: 'no-repeat',
                    borderRadius: '10px',
                }} className="product-image" onClick={openClose}>

                </div>
                <h3>Описание</h3>
                <p>Универсально-заточные станки для инструментов серии MQ предназначены для заточки инструментов из
                    быстрорежущей стали, твердых сплавов и других материалов. С применением приспособлений, поставляемых
                    за отдельную плату, станки можно использовать для заточки цилиндрических, наружных и плоских канавок
                    и формовочных поверхностей, а также шлифовать разнообразные зубчатые колеса, червячные фрезы,
                    фасонные фрезы, концевые сферические фрезы, спиральные сверла, конические развертки и проч.
                    <br/>На станке модели MQ-6025A продольное перемещение рабочего стола и подъем колонны шлифовальной
                    головки осуществляются вручную, на станке модели MQ-6025YA – в т.ч. механизировано, посредством
                    гидропривода станка.
                    <br/>Конструкция шлифовального узла подвижная, что позволяет производить установку угла заточки /
                    шлифовки в двухмерном пространстве. Рабочая передняя бабка вращается в двух направлениях и имеет с
                    одного торца конус NT50, с другого – внутренний конус Морзе, которые соответствуют международным
                    стандартам, и используются для базирования различных типов инструментов и установки цанг. Рабочий
                    стол установлен на прецизионные линейные направляющие с усиленной нагрузкой.
                </p>


            </div>


            <h3>Технические характеристики</h3>

            <Table striped bordless="true" hover>
                <thead>
                <tr className='table-title'>
                    <th>Характеристика</th>
                    <th>Значение A\YA</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>Максимальное расстояние между центрами, мм</td>
                    <td>700\650</td>
                </tr>
                <tr>
                    <td>Максимальный диаметр обработки, мм</td>
                    <td>250</td>
                </tr>
                <tr>
                    <td>Высота центров, мм</td>
                    <td>130</td>
                </tr>
                <tr>
                    <td>Размеры рабочего стола (ДхШ), мм</td>
                    <td>940 х 135</td>
                </tr>
                <tr>
                    <td>Максимальная длина шлифовки, мм</td>
                    <td>270</td>
                </tr>
                </tbody>
            </Table>

        </Container>
    )
}
