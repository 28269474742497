
import Card from "../../components/Card";

import SMG050 from "../../image/products/SMG050.png";
import SMG250 from "../../image/products/SMG250.png";
import Helmet from 'react-helmet';


export default function DrillBit() {
    return (
        <div className="container">
            <Helmet>
                <title>Станки для концевого инструмента(фрезы, сверла, бор-фрезы)</title>
                <meta name="description"
                      content="Список станков подходящие для обработки концевого инструмента"/>
                <meta name="keywords" content="концевого, инструмента, Станки"/>
                <meta name="robots" content="index, follow"/>
                <link rel="canonical" href="https://stm-market.ru/DrillBit"/>
            </Helmet>
            <h1>Станки для концевого инструмента(фрезы, сверла, бор-фрезы)</h1>

            <div className="goods">
                <Card image={SMG050} name='Станок шлифовально-заточной с ЧПУ SMG 050-й серии' link = '/SMG050'/>
                <Card image={SMG250} name='Станок шлифовально-заточной с ЧПУ SMG 250-й серии' link = '/SMG250'/>

            </div>

        </div>
    );
}

