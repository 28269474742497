import {Link} from "react-router-dom";

export default function ToolsCard(props) {
    return (
        <div className="tools-card-block">
            <Link to={props.link}>
                <div className="tools-cards" style={{
                    backgroundImage: 'url(' + props.image + ')',
                    backgroundSize: '90%, cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: "center center"
                }}>

                </div>
            </Link>
            <p className='card-text'>{props.name}</p>
        </div>
    )
}
