import Navbar from "./components/Navbar"
import Footer from "./components/Footer";
import {Navigate, Route, Routes} from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Products from "./pages/Products";
import Tools from "./pages/Tools";
import Contacts from "./pages/Contacts";
import P642 from "./pages/products/p642";
import SMG405 from "./pages/products/SMG405";
import SMG630 from "./pages/products/SMG630";
import P400 from "./pages/products/p400";
import SerMq from "./pages/products/SerMq";
import P6025 from "./pages/products/6025";
import M3080 from "./pages/products/M3080";
import SMG150 from "./pages/products/SMG150";
import SMG360 from "./pages/products/SMG360";
import TTK4020 from "./pages/products/TTK4020";
import SMG050 from "./pages/products/SMG050";
import SMG250 from "./pages/products/SMG250";
import GearGrindingG from "./pages/tools/GearGrindingG";
import Shaft from "./pages/tools/Shaft";
import Axis from "./pages/tools/Axis";
import ThreadGrinding from "./pages/tools/ThreadGrinding";
import GrindingWheel from "./pages/tools/GrindingWheel";
import DrillBit from "./pages/tools/DrillBit";
import Broaches from "./pages/tools/Broaches";
import EndMillingCutter from "./pages/tools/EndMillingCutter";
import WormMillingCutter from "./pages/tools/WormMillingCutter";
import Event from "./pages/Event";
import CuttingTools from "./pages/tools/CuttingTools";


function App() {
    return (


    <div className='window'>

        <Navbar/>
        <Routes>
            <Route path="/" element={<Home/>}/>
            <Route path="/products" element={<Products/>}/>
            <Route path="/about" element={<About/>}/>
            <Route path="/tools" element={<Tools/>}/>
            <Route path="/contact" element={<Contacts/>}/>
            <Route path="/products/SMG630" element={<SMG630/>}/>
            <Route path="/products/p6025" element={<P6025/>}/>
            <Route path="/products/SerMq" element={<SerMq/>}/>
            <Route path="/products/p642" element={<P642/>}/>
            <Route path="/products/SMG405" element={<SMG405/>}/>
            <Route path="/products/M3080" element={<M3080/>}/>
            <Route path="/products/SMG150" element={<SMG150/>}/>
            <Route path="/products/SMG360" element={<SMG360/>}/>
            <Route path="/products/TTK4020" element={<TTK4020/>}/>
            <Route path="/products/SMG050" element={<SMG050/>}/>
            <Route path="/products/SMG250" element={<SMG250/>}/>
            <Route path="/tools/GearGrindingG" element={<GearGrindingG/>}/>
            <Route path="/tools/Shaft" element={<Shaft/>}/>
            <Route path="/tools/Axis" element={<Axis/>}/>
            <Route path="/tools/ThreadGrinding" element={<ThreadGrinding/>}/>
            <Route path="/tools/GrindingWheel" element={<GrindingWheel/>}/>
            <Route path="/tools/DrillBit" element={<DrillBit/>}/>
            <Route path="/tools/Broaches" element={<Broaches/>}/>
            <Route path="/tools/EndMillingCutter" element={<EndMillingCutter/>}/>
            <Route path="/tools/WormMillingCutter" element={<WormMillingCutter/>}/>
            <Route path="/products/p400" element={<P400/>}/>
            <Route path="/Event" element={<Event/>}/>
            <Route path="/tools/CuttingTools" element={<CuttingTools/>}/>
            <Route path="*" element={<Navigate to="/" />} />
        </Routes>

        <Footer/>

    </div>

    );
}

export default App;
