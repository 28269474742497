import {Container, Table} from "react-bootstrap";
import productImg from "../../image/products/SMG250.png"
import Helmet from 'react-helmet';
import './Product.scss';
import {useState} from "react";

export default function SMG250() {
    const [fullscreen, setFullscreen] = useState(false)

    const openClose = () => {
        setFullscreen(!fullscreen)
    }
    return (
        <Container id="product-container">
            <Helmet>
                <title>Станок шлифовально-заточной с ЧПУ SMG 250-й сериии</title>
                <meta name="description"
                      content="Шлифовально-заточные станки с ЧПУ SMG 250-й серии предназначены для шлифования сложных винтовых
                    профилей и формообразования режущей части инструментов (концевые цилиндрические фрезы с прямым
                    торцом, концевые конические фрезы с прямым торцом, концевые цилиндрические фрезы со сферическим
                    торцом, концевые конические фрезы со сферическим торцом, свёрла спиральные ступенчатые, развёртки
                    цилиндрические, развёртки конические, зенкеры цилиндрические, зенкеры конические, метчики, борфрезы
                    и т.д.)."/>
                <meta name="keywords" content="Шлифовально-заточные, 250-й серии, ЧПУ"/>
                <meta name="robots" content="index, follow"/>
                <link rel="canonical" href="https://stm-market.ru/SMG250"/>
            </Helmet>
            <h1>Станок шлифовально-заточной с ЧПУ SMG 250-й серии</h1>
            <div className={`full-image ${fullscreen ? 'active-screen' : null}`}>
                <div className="cross" onClick={openClose}>
                    <span className='cross-right'></span>
                    <span className='cross-left'></span>
                </div>
                <img className='fullscreen-img' src={productImg} alt={productImg}/>
                <div className="background"></div>

            </div>

            <div className='info'>
                <div style={{
                    backgroundImage: 'url(' + productImg + ')',
                    backgroundSize: '100%',
                    backgroundRepeat: 'no-repeat',
                    borderRadius: '10px',
                }} className="product-image" onClick={openClose}>
                </div>
                <h3>Описание</h3>
                <p>
                    Шлифовально-заточные станки с ЧПУ SMG 250-й серии предназначены для шлифования сложных винтовых
                    профилей и формообразования режущей части инструментов (концевые цилиндрические фрезы с прямым
                    торцом, концевые конические фрезы с прямым торцом, концевые цилиндрические фрезы со сферическим
                    торцом, концевые конические фрезы со сферическим торцом, свёрла спиральные ступенчатые, развёртки
                    цилиндрические, развёртки конические, зенкеры цилиндрические, зенкеры конические, метчики, борфрезы
                    и т.д.). <br/>
                    В качестве шлифовальных кругов применяются как высокостойкие эльборовые (СВN) специальные профильные
                    шлифовальные круги на керамической связке, так и эльборовые круги на металлическом основании, не
                    подлежащие правке на станке. Для обеспечения высоких качественных показателей инструмента обработка
                    осуществляется с применением смазочно-охлаждающей жидкости. <br/>
                    Базой станка является станина, на которой монтируются основные узлы станка. <br/>
                    Для доступа в рабочую зону служат передние раздвижные двери, которые снабжены электрическими
                    выключателями. На отдельно стоящей стойке установлена система отсоса масляного тумана,
                    предназначенная для отсоса и фильтрации аэрозолей из рабочей зоны. Электрошкаф оснащен системой
                    кондиционирования. Пульт управления расположен спереди станка и имеет возможность перемещаться в
                    удобное для оператора положение. <br/>
                    Станок оснащен централизованной автоматической системой смазки направляющих качения и ШВП. <br/>
                </p>
            </div>


            <h3>Технические характеристики</h3>

            <Table striped bordless="true" hover>
                <thead>
                <tr className='table-title'>
                    <th>Характеристика</th>
                    <th>Значение</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>Наибольший диаметр детали (заготовки), мм</td>
                    <td>200</td>
                </tr>
                <tr>
                    <td>Максимальная длина детали в центрах, мм</td>
                    <td>400</td>
                </tr>
                </tbody>
            </Table>
        </Container>
    )
}
