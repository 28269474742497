
import Card from "../../components/Card";

import SMG360 from "../../image/products/SMG360.png";
import Helmet from 'react-helmet';


export default function ThreadGrinding() {
    return (
        <div className="container">
            <Helmet>
                <title>Станки для метчиков</title>
                <meta name="description"
                      content="Список станков подходящие для обработки обработки метчиков"/>
                <meta name="keywords" content="метчиков, обработки, Станки"/>
                <meta name="robots" content="index, follow"/>
                <link rel="canonical" href="https://stm-market.ru/ThreadGrinding"/>
            </Helmet>
            <h1>Станки для метчиков</h1>

            <div className="goods">
                <Card image={SMG360} name='Станок резьбошлифовальный с ЧПУ SMG 360-й серии' link = '/SMG360'/>
            </div>

        </div>
    );
}

