import Card from "../components/Card";
import SMG630 from "../image/products/SMG630.png";
import P6025 from "../image/products/6025.png";
import p642 from "../image/products/642.png";
import p400 from "../image/products/sr-400.png";
import SerMQ from "../image/products/SerMQ.png";
import SMG405 from "../image/products/SMG405.png";
import M3080 from "../image/products/M3080.png";
import SMG150 from "../image/products/SMG150.png";
import SMG360 from "../image/products/SMG360.png";
import TTK4020 from "../image/products/TTK4020.png";
import SMG050 from "../image/products/SMG050.png";
import SMG250 from "../image/products/SMG250.png";
import {Helmet} from 'react-helmet';

function Products() {
    return (

        <div className="container">
            <Helmet>
                <title>Станки</title>
                <meta name="description"
                      content="Станки от компании СТМ-маркет, здесь вы можете выбрать выбрать нужный вам станок"/>
                <meta name="keywords"
                      content="Станки, SMG 630, червячных фрез, универсальный 642, для червячных фрез 400,  для инструментов GD-6025Q, для инструментов серии MQ,
                      с ЧПУ SMG 405-й серии, для внутренней и наружной шлифовки GD-M3080A/B,
                       с ЧПУ SMG 150-й серии,
                       с ЧПУ SMG 360-й серии,
                       Стенд испытательный механической прочности шлифовальных кругов,
                       шлифовально-заточной с ЧПУ SMG 050,
                       шлифовально-заточной с ЧПУ SMG 250"/>
                <meta name="robots" content="index, follow"/>
                <link rel="canonical" href="https://stm-market.ru/products"/>
            </Helmet>
            <h1>Станки</h1>

            <div className="goods">
                <Card image={SMG630} name='Станок заточной с ЧПУ для торцовых фрез SMG 630-й серии' link='/products/SMG630'/>
                <Card image={p400} name='Станок заточной с ЧПУ для червячных фрез SMG 400-й серии' link='/products/p400'/>
                <Card image={p642} name='Станок заточной универсальный 642-й серии' link='/products/p642'/>
                <Card image={P6025} name='Станок универсально-заточный для инструментов GD-6025Q' link='/products/P6025'/>
                <Card image={SerMQ} name='Станок универсально-заточной для инструментов серии MQ' link='/products/SerMq'/>
                <Card image={SMG405} name='Станок зубошлифовальный с ЧПУ SMG 405-й серии' link='/products/SMG405'/>
                <Card image={M3080} name='Станок круглошлифовальный для внутренней и наружной шлифовки GD-M3080A/B'
                      link='/products/M3080'/>
                <Card image={SMG150} name='Станок профилешлифовальный с ЧПУ SMG 150-й серии' link='/products/SMG150'/>
                <Card image={SMG360} name='Станок резьбошлифовальный с ЧПУ SMG 360-й серии' link='/products/SMG360'/>
                <Card image={TTK4020} name='Стенд испытательный механической прочности шлифовальных кругов ТТК4020'
                      link='/products/TTK4020'/>
                <Card image={SMG050} name='Станок шлифовально-заточной с ЧПУ SMG 050-й серии' link='/products/SMG050'/>
                <Card image={SMG250} name='Станок шлифовально-заточной с ЧПУ SMG 250-й серии' link='/products/SMG250'/>


                {/*<Card image={p3e692} name='Полуавтомат заточной для дисковых пил 3Е692' link = '/p3Е692'/>*/}

            </div>

        </div>
    );
}

export default Products
