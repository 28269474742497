import {Container, Table} from "react-bootstrap";
import productImg from "../../image/products/SMG405.png"
import Helmet from 'react-helmet';
import './Product.scss';
import {useState} from "react";

export default function SMG405() {
    const [fullscreen, setFullscreen] = useState(false)

    const openClose = () => {
        setFullscreen(!fullscreen)
    }
    return (
        <Container id="product-container">
            <Helmet>
                <title>Станок зубошлифовальный с ЧПУ SMG 405-й серии</title>
                <meta name="description"
                      content="Прецизионные зубошлифовальные станки с ЧПУ SMG 405-й серии предназначены для шлифования
                    цилиндрических прямозубых и косозубых зубчатых колёс внешнего зацепления методом копирования
                    (профильного шлифования) "/>
                <meta name="keywords" content="зубошлифовальный, 405-й серии, ЧПУ"/>
                <meta name="robots" content="index, follow"/>
                <link rel="canonical" href="https://stm-market.ru/SMG405"/>
            </Helmet>
            <h1>Станок зубошлифовальный с ЧПУ SMG 405-й серии</h1>
            <div className={`full-image ${fullscreen ? 'active-screen' : null}`}>
                <div className="cross" onClick={openClose}>
                    <span className='cross-right'></span>
                    <span className='cross-left'></span>
                </div>
                <img className='fullscreen-img' src={productImg} alt={productImg}/>
                <div className="background"></div>

            </div>
            <div className='info'>
                <div style={{
                    backgroundImage: 'url(' + productImg + ')',
                    backgroundSize: '100%',
                    backgroundRepeat: 'no-repeat',
                    borderRadius: '10px',
                }} className="product-image" onClick={openClose}>
                </div>
                <h3>Описание</h3>
                <p>Прецизионные зубошлифовальные станки с ЧПУ SMG 405-й серии предназначены для шлифования
                    цилиндрических прямозубых и косозубых зубчатых колёс внешнего зацепления методом копирования
                    (профильного шлифования) <br/>
                    При профильном шлифовании практически весь профиль зуба шлифуется за один ход круга, что существенно
                    повышает производительность обработки. <br/>
                    Применение смазочно-охлаждающей жидкости при шлифовании позволяет достичь высоких показателей
                    качества обработанной поверхности, сохраняя производительность. В качестве шлифовальных кругов могут
                    применяться абразивные и CBN круги на керамической связке. Фасонная правка шлифовальных кругов на
                    керамической связке производится контурным методом непосредственно на станке. <br/>
                    Станок оснащается системой подготовки управляющих программ. Программные модули предназначены для
                    настройки и записи референтных точек станка, ввода параметров зубчатых колёс, шлифовального круга и
                    технологических параметров, расчета установок и траекторий перемещений осей станка и их коррекции,
                    создание управляющих программ шлифования, измерения параметров зубчатого колеса, фасонной правки
                    шлифовального круга и формирование протокола измерения параметров зубчатого колеса. <br/>
                    Оборудование предназначено для использования в условиях единичного и серийного производства. <br/>
                </p>


            </div>


            <h3>Технические характеристики</h3>

            <Table striped bordless="true" hover>
                <thead>
                <tr className='table-title'>
                    <th>Характеристика</th>
                    <th>Значение</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>Наибольший диаметр/модуль, мм</td>
                    <td>400/ 10</td>
                </tr>
                <tr>
                    <td>Наибольшая длина шлифования, мм</td>
                    <td>300</td>
                </tr>
                <tr>
                    <td>Наибольшая длина детали, установленной в центрах, мм</td>
                    <td>400</td>
                </tr>
                <tr>
                    <td>Шероховатость обработанной поверхности образца-изделия, мкм</td>
                    <td>Ra ≤ 0,63</td>
                </tr>
                <tr>
                    <td>Точность обработки образца-изделия по ГОСТ 1643-81</td>
                    <td>5</td>
                </tr>
                </tbody>
            </Table>

        </Container>
    )
}
