import {Container, Table} from "react-bootstrap";
import productImg from "../../image/products/sr-400.png"
import Helmet from 'react-helmet';
import {useState} from "react";
import './Product.scss';


export default function P400() {
    const [fullscreen, setFullscreen] = useState(false)

    const openClose = () => {
        setFullscreen(!fullscreen)
    }
    return (
        <Container id="product-container">
            <Helmet>
                <title>Станок заточной с ЧПУ для червячных фрез SMG 400-й серии</title>
                <meta name="description" content="Заточные станки с ЧПУ SMG 400-й серии предназначены для заточки высокоточных производительных
                    инструментов – червячных фрез."/>
                <meta name="keywords" content="червячных, 400-й серии, ЧПУ"/>
                <meta name="robots" content="index, follow"/>
                <link rel="canonical" href="https://stm-market.ru/p400"/>
            </Helmet>
            <h1>Станок заточной с ЧПУ для червячных фрез SMG 400-й серии</h1>
            <div className={`full-image ${fullscreen ? 'active-screen' : null}`}>
                <div className="cross" onClick={openClose}>
                    <span className='cross-right'></span>
                    <span className='cross-left'></span>
                </div>
                <img className='fullscreen-img' src={productImg} alt={productImg}/>
                <div className="background"></div>

            </div>
            <div className='info'>
                <div style={{
                    backgroundImage: 'url(' + productImg + ')',
                    backgroundSize: '100%',
                    backgroundRepeat: 'no-repeat',
                    borderRadius: '10px',
                }} className="product-image" onClick={openClose}>
                </div>
                <h3>Описание</h3>
                <p>Заточные станки с ЧПУ SMG 400-й серии предназначены для заточки высокоточных производительных
                    инструментов – червячных фрез. Высокое качество заточных станков с ЧПУ для червячных фрез
                    обеспечивает заточку одно- и многозаходных червячных фрез с прямой и винтовой стружечной канавкой из
                    быстрорежущей стали и твердого сплава по классу ААА. Диапазон диаметров обрабатываемых червячных
                    фрез от 40 до 400 мм, диапазон модулей от 14 до 30 мм в зависимости от исполнения станка.
                    <br/>Заточной станок с ЧПУ SMG для червячных фрез 400-й серии оснащается собственным программным
                    обеспечением, которое позволяет оператору вводить в диалоговом режиме параметры червячной фрезы,
                    указывать режимы резания, параметры и размеры шлифовального круга и, в результате, получить
                    сформированную автоматически управляющую программу для обработки червячной фрезы и правки круга.
                    <br/>Измерительное устройство дает возможность измерять и оценивать фактические параметры профиля по
                    сравнению с требуемыми. Эта функция, в основном, используется для оптимизации процессов настройки в
                    станке, в частности, для устранения нерабочих периодов, возникающих из-за внешних измерительных
                    процессов.
                </p>


            </div>


            <h3>Технические характеристики</h3>

            <Table striped bordless="true" hover>
                <thead>
                <tr className='table-title'>
                    <th>Характеристика</th>
                    <th>Значение</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>Наибольшая длина, устанавливаемая в центрах, мм</td>
                    <td>450</td>
                </tr>
                <tr>
                    <td>Наибольший диаметр/модуль, мм</td>
                    <td>400/ 30</td>
                </tr>
                <tr>
                    <td>Наибольший диаметр мм</td>
                    <td>40</td>
                </tr>
                <tr>
                    <td>Угол подъема винтовой стружечной канавки, град ± 30</td>
                    <td>± 30</td>
                </tr>
                <tr>
                    <td>Точность и шероховатость заточки</td>
                    <td>A, AA, ААА</td>
                </tr>
                </tbody>
            </Table>


        </Container>
    )
}
