import Card from "../components/ToolsCard";
import CuttingTools from "../image/tools/CuttingTools.png";
import GearGrindingG from "../image/tools/GearGrindingG.png";
import Shaft from "../image/tools/Shaft.jpg";
import GrindingWheel from "../image/tools/GrindingWheel.jpg";
import Axis from "../image/tools/Axis.png";
import ThreadGrinding from "../image/tools/ThreadGrinding.png";
import DrillBit from "../image/tools/DrillBit.png";
import Broaches from "../image/tools/Broaches.png";
import EndMillingCutter from "../image/tools/EndMillingCutter.png";
import WormMillingCutter from "../image/tools/WormMillingCutter.png";
import {Helmet} from 'react-helmet';


function Tools() {
    return (
        <div className="container">
            <Helmet>
                <title>Инструменты</title>
                <meta name="description" content="Навигаторе по обрабатываемым деталям на станках  stm-market"/>
                <meta name="keywords"
                      content="Режущий инструмент, Шестерни, Валы, Оси, Метчики, Шлифовальный круги, Концевой инструмент, Протяжки, Торцевые фрезы, Червячние фрезы"/>
                <meta name="robots" content="index, follow"/>
                <link rel="canonical" href="https://stm-market.ru/tools"/>
            </Helmet>
            <h1>Обрабатываемые инструменты</h1>

            <div className="goods">
                <Card image={CuttingTools} name='Режущий инструмент' link='/tools/CuttingTools'/>
                <Card image={GearGrindingG} name='Шестерни' link='/tools/GearGrindingG'/>
                <Card image={Shaft} name='Валы' link='/tools/Shaft'/>
                <Card image={Axis} name='Оси' link='/tools/Axis'/>
                <Card image={ThreadGrinding} name='Метчики' link='/tools/ThreadGrinding'/>
                <Card image={GrindingWheel} name='Шлифовальные круги' link='/tools/GrindingWheel'/>
                <Card image={DrillBit} name='Концевой инструмент' link='/tools/DrillBit'/>
                <Card image={Broaches} name='Протяжки' link='/tools/Broaches'/>
                <Card image={EndMillingCutter} name='Торцевые фрезы' link='/tools/EndMillingCutter'/>
                <Card image={WormMillingCutter} name='Червячные фрезы' link='/tools/WormMillingCutter'/>
            </div>

        </div>
    );
}

export default Tools
