import {Link} from "react-router-dom";

export default function Card(props) {
    return (
        <div className="card-block">
            <Link to={props.link}>
                <div className="cards" style={{
                    backgroundImage: 'url(' + props.image + ')',
                    backgroundSize: '90%, cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: "center center"
                }}>

                </div>
                <p className='card-text'>{props.name}</p>
            </Link>

        </div>
    )
}
