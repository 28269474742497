import {Container, Table} from "react-bootstrap";
import productImg from "../../image/products/TTK4020.png"
import Helmet from 'react-helmet';
import './Product.scss';
import {useState} from "react";

export default function TTK4020() {
    const [fullscreen, setFullscreen] = useState(false)

    const openClose = () => {
        setFullscreen(!fullscreen)
    }
    return (
        <Container id="product-container">
            <Helmet>
                <title>Стенд испытательный механической прочности шлифовальных кругов ТТК4020</title>
                <meta name="description"
                      content=" Стенды испытательные механической прочности шлифовальных кругов ТТК4020 предназначены для испытания
                    вращением на механическую прочность (на разрыв вращением)"/>
                <meta name="keywords" content="Стенд, испытательный, механической, прочности, шлифовальных, кругов, ТТК4020"/>
                <meta name="robots" content="index, follow"/>
                <link rel="canonical" href="https://stm-market.ru/TTK4020"/>
            </Helmet>
            <h1>Стенд испытательный механической прочности шлифовальных кругов ТТК4020</h1>
            <div className={`full-image ${fullscreen ? 'active-screen' : null}`}>
                <div className="cross" onClick={openClose}>
                    <span className='cross-right'></span>
                    <span className='cross-left'></span>
                </div>
                <img className='fullscreen-img' src={productImg} alt={productImg}/>
                <div className="background"></div>

            </div>

            <div className='info'>
                <div style={{
                    backgroundImage: 'url(' + productImg + ')',
                    backgroundSize: '100%',
                    backgroundRepeat: 'no-repeat',
                    borderRadius: '10px',
                }} className="product-image" onClick={openClose}>
                </div>
                <h3>Описание</h3>
                <p>
                    Стенды испытательные механической прочности шлифовальных кругов ТТК4020 предназначены для испытания
                    вращением на механическую прочность (на разрыв вращением) следующих видов абразивных кругов:
                    шлифовальных, обдирочных, заточных, зачистных, отрезных, полировальных, лепестковых, фибровых. <br/>
                    Цикл испытания шлифовальных кругов на данном стенде предусматривает плавный разгон вращения шпинделя
                    шлифовального круга до расчетной скорости, удержание вращения шпинделя шлифовального круга на
                    расчетной скорости, плавное торможение шпинделя шлифовального круга до останова. <br/>
                    При испытаниях расчет скорости вращения шпинделя шлифовального круга производится с помощью системы
                    управления стенда на базе программируемого логического контроллера. <br/>
                    Стенд оснащен панелью, служащей для ввода параметров испытаний, управления работой стенда, а также
                    мониторинга испытаний и состояния блокировочной системы стенда, которая, в свою очередь,
                    предназначена для обеспечения безопасных условий проведения испытаний. <br/>
                    *Посадочные диаметры круга, мм: 13; 25; 40 доступны по дополнительному согласованию
                </p>
            </div>


            <h3>Технические характеристики</h3>

            <Table striped bordless="true" hover>
                <thead>
                <tr>
                    <th>Характеристика</th>
                    <th>Значение</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>Наружный диаметр кругов, мм</td>
                    <td>80…450</td>
                </tr>
                <tr>
                    <td>Посадочный диаметр круга, мм*</td>
                    <td>20; 22; 32; 51; 76; 127; 203</td>
                </tr>
                </tbody>
            </Table>

        </Container>
    )
}
