import {Container, Table} from "react-bootstrap";
import productImg from "../../image/products/6025.png"
import Helmet from 'react-helmet';
import {useState} from "react";
import './Product.scss';


export default function P6025() {
    const [fullscreen, setFullscreen] = useState(false)

    const openClose = () => {
        setFullscreen(!fullscreen)
    }
    return (
        <Container id="product-container">
            <Helmet>
                <title>Станок универсально-заточный для инструментов GD-6025Q</title>
                <meta name="description" content="Универсально-заточные станки для инструментов GD-6025Q предназначены для заточки и переточки режущего инструмента."/>
                <meta name="keywords" content="универсально-заточный, GD-6025Q, высокоточными" />
                <meta name="robots" content="index, follow"/>
                <link rel="canonical" href="https://stm-market.ru/P6025"/>
            </Helmet>
            <h1>Станок универсально-заточный для инструментов GD-6025Q</h1>
            <div className={`full-image ${fullscreen ? 'active-screen' : null}`}>
                <div className="cross" onClick={openClose}>
                    <span className='cross-right'></span>
                    <span className='cross-left'></span>
                </div>
                <img className='fullscreen-img' src={productImg} alt={productImg}/>
                <div className="background"></div>

            </div>
            <div className='info'>
                <div style={{
                    backgroundImage: 'url(' + productImg + ')',
                    backgroundSize: '100%',
                    backgroundRepeat: 'no-repeat',
                    borderRadius: '10px',
                }} className="product-image" onClick={openClose}>
                </div>
                <h3>Описание</h3>
                <p>Универсально-заточные станки для инструментов GD-6025Q предназначены для заточки и переточки режущего
                    инструмента. Исполнение станка – настольное.
                    <br/>Рабочий стол станка оснащен высокоточными линейными направляющими. Станина характеризуется
                    устойчивостью и легкой управляемостью. Также есть возможность регулировки рабочего стола, а также
                    степени конусности, в шпинделе используется импортный подшипник. <br/>
                    По заявке Покупателя станок может быть укомплектован различными приспособлениями, с помощью которых
                    можно выполнять операции по обработке таких инструментов, как профильная фреза, концевая фреза,
                    R-образная фреза, развертка, токарный резец, R-образный токарный резец, червячная фреза, сверло,
                    метчик, штихель, инструмент для силовой резки, зенковка, закругленные гильотины, наружные и плоские
                    канавки, формовочные поверхности и др.
                </p>


            </div>


            <h3>Технические характеристики</h3>

            <Table striped bordless="true" hover>
                <thead>
                <tr className='table-title'>
                    <th>Характеристика</th>
                    <th>Значение</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>Максимальный наружный диаметр обтачиваемого изделия, мм</td>
                    <td>250</td>
                </tr>
                <tr>
                    <td>Максимальный внутренний диаметр обтачиваемого изделия, мм</td>
                    <td>80</td>
                </tr>
                <tr>
                    <td>Расстояние поднятия и спуска шлифовального диска в вертикальном направлении, мм</td>
                    <td>140</td>
                </tr>
                <tr>
                    <td>Габариты рабочего стола, мм</td>
                    <td>130 х 680</td>
                </tr>
                </tbody>
            </Table>
        </Container>
    )
}
